import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// constants
import constants from "../../constants";

// components
import FooterTerms from "./FooterTerms";

// styles
import * as styles from "./FooterV1.module.css";

const Footer = props => {
  const { contact, socialLinks } = constants.HOME_PAGE;

  return (
    <>
      <div className={`${styles.footerWrapperTop}`}>
        <div
          className={`container ${styles.footerContainer} ${styles.footerContainerTop}`}
        >
          <div className="row">
            <div className="col-6 col-md-9">
              <div className="row">
                <div className="col-12 col-md-4 mt-3">
                  <a href={`tel:+91${contact.phone}`} className="navLink">
                    <div className="d-flex align-items-center">
                      <StaticImage
                        src="../../images/footer/call.svg"
                        alt="call"
                        className={`img-fluid ${styles.footerCall_image}`}
                        placeholder="blurred"
                        quality={100}
                      />
                      <div className={`${styles.footerCall}`}>
                        {contact.phone}
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-md-8 mt-3">
                  <a href={`mailto:${contact.email}`} className="navLink">
                    <div className="d-flex align-items-center">
                      <StaticImage
                        src="../../images/footer/email.svg"
                        alt="email"
                        className={`img-fluid ${styles.footerCall_image}`}
                        placeholder="blurred"
                        quality={100}
                      />
                      <div className={`${styles.footerCall}`}>
                        {contact.email}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 mt-3">
                  <div className="d-flex align-items-center">
                    <div className={`${styles.footerCall}`}>
                      Twins Digital Services India Private Limited
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8 mt-3">
                  <div className="d-flex align-items-center">
                    <div className={`${styles.footerCall}`}>
                      E Block, 9th Floor, IITM Research Park, Kanagam Road,
                      Taramani, Chennai – 600 113,
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mt-3">
                  <div className={`${styles.footerCall}`}>
                    CIN:- U72900TN2018FTC125517
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 mt-3 text-end">
              <div className={`${styles.footerFollowUs}`}>follow us at</div>
              <a
                className="navLink me-2"
                href={socialLinks.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../../images/footer/facebook.svg"
                  alt="facebook"
                  className={`img-fluid ${styles.footerSocialIcon}`}
                  placeholder="blurred"
                  quality={100}
                />
              </a>
              <a
                className="navLink me-2"
                href={socialLinks.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../../images/footer/instagram.svg"
                  alt="instagram"
                  className={`img-fluid ${styles.footerSocialIcon}`}
                  placeholder="blurred"
                  quality={100}
                />
              </a>
              <a
                className="navLink me-2"
                href={socialLinks.youtube}
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../../images/footer/youtube.svg"
                  alt="youtube"
                  className={`img-fluid ${styles.footerSocialIcon}`}
                  placeholder="blurred"
                  quality={100}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <FooterTerms isHome={props.isHome} />
    </>
  );
};

export default Footer;
