import config from "../config";

const STATES = [
  { key: "Ahmedabad", value: "Ahmedabad" },
  { key: "Bengaluru", value: "Bengaluru" },
  { key: "Chennai", value: "Chennai" },
  { key: "Coimbatore", value: "Coimbatore" },
  { key: "Hyderabad", value: "Hyderabad" },
  { key: "Mumbai", value: "Mumbai" },
  { key: "NCR", value: "NCR" },
  { key: "Pune", value: "Pune" },
  { key: "Trichy", value: "Trichy" },
  { key: "Kochi", value: "Kochi" },
  { key: "Jaipur", value: "Jaipur" },
  { key: "Kolkata", value: "Kolkata" },
];

const REVIEWS = [
  {
    header: "Never thought that diabetes reversal was possible for me",
    subheader: `I was a diabetic for more than 5 years. Now, my sugar level is under control even without medicines. I got back my stamina to run a 21 km half marathon.`,
    rating: 5,
    showBloodLevel: true,
    bloodLevel: {
      text: "Blood Sugar",
      lowLevel: "124",
      highLevel: "233",
    },
    facebookVerified: true,
    userAvator: "NewDesignTwinhealth/Neil_s_result_j0znnq.png",
    userName: "Neil",
    userDetails: "45 Years, Delhi",
  },
  {
    header: "I thought that I was going to be under medication forever",
    subheader: `Twin changed the way I looked at diabetes. I, now, know that a diabetic can be diabetes-free. I can’t believe that I got off medicines within 10 days.`,
    rating: 5,
    showBloodLevel: true,
    bloodLevel: {
      text: "HbA1C dropped",
      lowLevel: "5.7",
      highLevel: "7.1",
    },
    facebookVerified: true,
    userAvator: "Webinar/DS_01109_fjzcje.jpg",
    userName: "Arnav",
    userDetails: "38 Years, Pune",
  },
  {
    header: "The Webinar was the first step towards diabetes reversal",
    subheader: `The doctor busted all kinds of diabetes related myths I had. Q&A session with the doctor & 1:1 consultation with the diabetes expert were turning points of my reversal.`,
    rating: 5,
    showBloodLevel: false,
    bloodLevel: {
      text: "Blood Sugar",
      lowLevel: "124 mg/dl",
      highLevel: "233 mg/dl",
    },
    facebookVerified: true,
    userAvator: "NewDesignTwinhealth/Shourin_big_m8bbmn.jpg",
    userName: "Shaurin",
    userDetails: "48 Years, Mumbai",
  },
  {
    header: "Every Diabetic should attend this webinar",
    subheader: `I have got a 360 degree understanding of the causes of diabetes, its impact on overall health, & the best possible ways to reverse diabetes. It was an eye-opener.`,
    rating: 5,
    showBloodLevel: false,
    bloodLevel: {
      text: "Blood Sugar",
      lowLevel: "124 mg/dl",
      highLevel: "233 mg/dl",
    },
    facebookVerified: true,
    userAvator: "NewDesignTwinhealth/Sunita_big_dlywfe.jpg",
    userName: "Sunita",
    userDetails: "56 Years, Bangalore",
  },
];

const GENDER = [
  { key: "MALE", value: "Male" },
  { key: "FEMALE", value: "Female" },
];

const PAYMENT_PLANS = [
  {
    key: "TWINQP_19750",
    value: "Pay Quarterly: ₹19,750 x 4 instalments (34% Off)",
  },
  {
    key: "TWINAP_69500",
    value: "Pay Upfront: ₹69,500 x 1 instalment (42% Off)",
  },
];

const BOOK_PACK = {
  quarterlylPlan: {
    offerPrice: 30000,
    planPrice: 22500,
    planCode: "GoldPro_Quarter_22500",
    planDay: "Quarterly",
    m2Type: "DIRECT_M2_QUARTERLY",
  },
  annualPlan: {
    offerPrice: 120000,
    planPrice: 72500,
    planCode: "GoldPro_Annual_72500",
    planDay: "Annual",
    m2Type: "DIRECT_M2_ANNUAL",
  },
  coupons: {
    quarterlylPlan: [
      {
        coupon: "TWINQP",
        code: "TWINQP_18750",
        price: 18750,
      },
      {
        coupon: "REVERSEQP",
        code: "TWINQP_19750",
        price: 19750,
      },
    ],
    annualPlan: [
      {
        coupon: "TWINAP",
        code: "TWINAP_64500",
        price: 64500,
      },
      {
        coupon: "REVERSEAP",
        code: "TAP_RIF",
        price: 67500,
      },
      // {
      // 	coupon: "TWINSPECIAL60",
      // 	code: "TWIN_AP_59990",
      // 	price: 59990,
      // },
    ],
  },
};

let TWIN_STORE = {
  plan1: {
    cgmOrderServicePurchased: "TWO_WEEKS",
    planCode: "cgmTestPlan",
    offerPrice: "2,990",
    totalPrice: "",
    offer: "",
  },
  plan2: {
    cgmOrderServicePurchased: "FOUR_WEEKS",
    planCode: "cgmTestPlan",
    offerPrice: "5,490",
    totalPrice: "5,980",
    offer: "8%",
  },
  plan3: {
    cgmOrderServicePurchased: "EIGHT_WEEKS",
    planCode: "cgmTestPlan",
    offerPrice: "10,990",
    totalPrice: "11,960",
    offer: "8%",
  },
};

if (config.APP_ENV === "production") {
  TWIN_STORE = {
    plan1: {
      cgmOrderServicePurchased: "TWO_WEEKS",
      planCode: "TSA_Services_2Week",
      offerPrice: "2,990",
      totalPrice: "",
      offer: "",
    },
    plan2: {
      cgmOrderServicePurchased: "FOUR_WEEKS",
      planCode: "TSA_Service_4week",
      offerPrice: "5,490",
      totalPrice: "5,980",
      offer: "8%",
    },
    plan3: {
      cgmOrderServicePurchased: "EIGHT_WEEKS",
      planCode: "TSA_Service_8week",
      offerPrice: "10,990",
      totalPrice: "11,960",
      offer: "8%",
    },
  };
}

const HOME_PAGE = {
  contact: {
    email: "care@twinhealth.com",
    phone: "9005008946",
  },
  socialLinks: {
    facebook: "https://www.facebook.com/twinhealthindia?mibextid=ZbWKwL",
    linkedin: "https://www.linkedin.com/company/twinforhealth",
    instagram: "https://www.instagram.com/twinhealthindia/",
    youtube: "https://www.youtube.com/channel/UCIerFf2GW7nuLlcDtbgNiGA",
  },
};

const BOOKPACK_LANGUAGES = [
  {
    name: "en",
    display: "English",
  },
  {
    name: "bn",
    display: "Bengali",
  },
  {
    name: "gu",
    display: "Gujarati",
  },
  {
    name: "hi",
    display: "Hindi",
  },
  {
    name: "kn",
    display: "Kannada",
  },
  {
    name: "ml",
    display: "Malayalam",
  },
  {
    name: "mr",
    display: "Marathi",
  },
  {
    name: "ta",
    display: "Tamil",
  },
  {
    name: "te",
    display: "Telugu",
  },
  {
    name: "pa",
    display: "Punjabi",
  },
];

const BOOKPACK_ERROR_MESSAGES = {
  serverError:
    "There seems to be an issue at our end. Please call 08069259499 to complete the payment",
};

const BOOKPACK_PINCODE_MESSAGES = {
  serviceable: {
    msg1: "Your location is serviceable!",
    msg2: "We are serving many members in your location already. Welcome to the club!",
  },
  remote: {
    msg1: "Remote operations are available.",
    msg2: "Your location is not serviceable in person. A one-time payment of ₹10,000 is added to cover the cost of the sensor reader.",
  },
  notServiceable: {
    msg1: "Sorry! We are not operational in your location yet.",
    msg2: "Please share your details below to learn about our launch in your location in the near future.",
  },
  default: {
    msg: "  Please share your nearest pincode to whether twin is serviceable in your location",
  },
};

const BOOKPACK_CATEGORIES = {
  TIER_I: "TIER_I",
  TIER_II: "TIER_II",
  TIER_III: "TIER_III",
};

const DOCTOR_REFERRAL = {
  contact: {
    email: "drcare@twinhealth.com",
  },
};

const CSV_UPLOAD_VIDEOS = {
  libreWindowsLink:
    "https://player.vimeo.com/video/816460063?h=24a3c05ca9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  libreMacLink:
    "https://player.vimeo.com/video/816422643?h=946f74d6b7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  libreProWindowsLink:
    "https://player.vimeo.com/video/816422345?h=bd8cd11ba9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
};

const CSV_UPLOAD_DOWNLOAD_LINKS = {
  libreWindowsLink:
    "https://drive.google.com/file/d/1qt2Gp7w4NqT8yON7zOCdKMxYCk39XETb/view",
  libreMacLink: "https://www.libreview.com/",
  libreProWindowsLink:
    "https://drive.google.com/file/d/1YeZ-ZaecrIL26a-hZn-EOu_0KmzxErxe/view",
};

const GET_IN_TOUCH_LANGUAGES = [
  {
    name: "en",
    display: "English",
  },
  {
    name: "bn",
    display: "Bengali",
  },
  {
    name: "gu",
    display: "Gujarati",
  },
  {
    name: "hi",
    display: "Hindi",
  },
  {
    name: "kn",
    display: "Kannada",
  },
  {
    name: "ml",
    display: "Malayalam",
  },
  {
    name: "mr",
    display: "Marathi",
  },
  {
    name: "ta",
    display: "Tamil",
  },
  {
    name: "te",
    display: "Telugu",
  },
  {
    name: "pa",
    display: "Punjabi",
  },
];

const GET_IN_TOUCH_TIMES = [
  {
    name: "10am-1pm",
    display: "10AM - 1PM",
  },
  {
    name: "1pm-5pm",
    display: "1AM - 5PM",
  },
  {
    name: "5pm-8pm",
    display: "5AM - 8PM",
  },
];

const HOME_PAGE_V2 = {
  topBannerVideoLink:
    "https://player.vimeo.com/progressive_redirect/playback/966395849/rendition/720p/file.mp4?loc=external&log_user=0&signature=9a0f15df5fe38bba0abd859df4ae267320aa82f22ee51f36939046c639ad3bcf",
  adaLink:
    "https://diabetes.org/newsroom/press-releases/2022/artificial-intelligence-offers-significant-rate-remission-type-2-diabetes-compared-to-standard-care",
  floatingLeftCtaText: "Get in touch",
  floatingLeftCtaLink: "/#getInTouch",
  floatingRightCtaText: "Enroll Now",
  floatingRightCtaLink: "/wbdt-care-plan",
  twinCanHelpVideoLink:
    "https://player.vimeo.com/progressive_redirect/playback/969488118/rendition/1080p/file.mp4?loc=external&log_user=0&signature=578b7c244f7d2343efcd1e341c7c840a92729251a8b41778381a7bcd41caa2d7",
  whatIsTwinVideoLink: "https://www.youtube.com/embed/wlnv1ooaxCg",
};

const METABOLIC_ASSESSMENT_QUESTIONS = [
  {
    id: 1,
    question: "What is your height (Ft) ?",
    options: [],
    type: "dropdown",
  },
  {
    id: 2,
    question: "What is your weight (Kg) ?",
    options: [],
    type: "inputtext",
  },
  {
    id: 3,
    question: "What is your current blood sugar levels (HBA1C) ?",
    options: [
      {
        option: "Less than 7",
        score: "22",
        id: 1,
      },
      {
        option: "7 - 7.9",
        score: "18",
        id: 2,
      },
      {
        option: "8 - 8.9",
        score: "13",
        id: 3,
      },
      {
        option: "9 - 9.9",
        score: "9",
        id: 4,
      },
      {
        option: "10 or more than 10",
        score: "4",
        id: 5,
      },
    ],
    type: "optionselect",
  },
  {
    id: 4,
    question:
      "How many medications are you taking currently to manage your Diabetes?",
    options: [
      {
        option: "No medications",
        score: "37",
        id: 1,
      },
      {
        option: "1 Tablet",
        score: "30",
        id: 2,
      },
      {
        option: "2 Tablets",
        score: "22",
        id: 3,
      },
      {
        option: "3 Tablets or Injectables",
        score: "15",
        id: 4,
      },
      {
        option: "Insulin",
        score: "7",
        id: 5,
      },
    ],
    type: "optionselect",
  },
  {
    id: 5,
    question: "What is your health care expense for the month?",
    options: [
      {
        option: "Under ₹1500",
        score: "0",
        id: 1,
      },
      {
        option: "₹1501 - ₹4000",
        score: "0",
        id: 2,
      },
      {
        option: "₹4001 - ₹6500",
        score: "0",
        id: 3,
      },
      {
        option: "Over ₹6500",
        score: "0",
        id: 4,
      },
      {
        option: "I do not take any medications",
        score: "0",
        id: 5,
      },
    ],
    type: "optionselect",
  },
  {
    id: 6,
    question: "How often you smoke ?",
    options: [
      {
        option: "I don't smoke",
        score: "15",
        id: 1,
      },
      {
        option: "Stopped smoking 10 years back",
        score: "12",
        id: 2,
      },
      {
        option: "Stopped smoking 5 years back",
        score: "9",
        id: 3,
      },
      {
        option: "Stopped smoking 1 year back",
        score: "6",
        id: 4,
      },
      {
        option: "Actively smoke everyday",
        score: "3",
        id: 5,
      },
    ],
    type: "optionselect",
  },
  {
    id: 7,
    question: "How often you consume alcohol ?",
    options: [
      {
        option: "I don't drink",
        score: "15",
        id: 1,
      },
      {
        option: "Once in a month",
        score: "12",
        id: 2,
      },
      {
        option: "1 - 2 times every week",
        score: "9",
        id: 3,
      },
      {
        option: "3 - 6 times every week",
        score: "6",
        id: 4,
      },
      {
        option: "Daily",
        score: "3",
        id: 5,
      },
    ],
    type: "optionselect",
  },
];
const QUIZ_HEIGHT = [
  {
    name: "121.92",
    display: "4'0''",
  },
  {
    name: "124.46",
    display: "4'1''",
  },
  {
    name: "127",
    display: "4'2''",
  },
  {
    name: "129.54",
    display: "4'3''",
  },
  {
    name: "132.08",
    display: "4'4''",
  },
  {
    name: "134.62",
    display: "4'5''",
  },
  {
    name: "137.16",
    display: "4'6''",
  },
  {
    name: "139.70",
    display: "4'7''",
  },
  {
    name: "142.24",
    display: "4'8''",
  },
  {
    name: "144.78",
    display: "4'9''",
  },
  {
    name: "147.32",
    display: "4'10''",
  },
  {
    name: "149.86",
    display: "4'11''",
  },
  {
    name: "152.40",
    display: "5'0''",
  },
  {
    name: "154.94",
    display: "5'1''",
  },
  {
    name: "157.48",
    display: "5'2''",
  },
  {
    name: "160.02",
    display: "5'3''",
  },
  {
    name: "162.56",
    display: "5'4''",
  },
  {
    name: "165.10",
    display: "5'5''",
  },
  {
    name: "167.64",
    display: "5'6''",
  },
  {
    name: "170.18",
    display: "5'7''",
  },
  {
    name: "172.72",
    display: "5'8''",
  },
  {
    name: "175.26",
    display: "5'9''",
  },
  {
    name: "177.80",
    display: "5'10''",
  },
  {
    name: "180.34",
    display: "5'11''",
  },
  {
    name: "182.88",
    display: "6'0''",
  },
  {
    name: "185.42",
    display: "6'1''",
  },
  {
    name: "187.96",
    display: "6'2''",
  },
  {
    name: "190.50",
    display: "6'3''",
  },
  {
    name: "193.04",
    display: "6'4''",
  },
  {
    name: "195.58",
    display: "6'5''",
  },
];
const METABOLIC_ASSESSMENT_RESULTPAGE_PARA = {
  heading1: "Congratulations on taking good care of your health.",
  heading2: "Time to get serious about your health, before it gets worse",
  heading3: "Time to get serious about your health.",
  subHeading1:
    "Your score is below 70, it suggests a deterioration in your diabetes condition and indicates that you may have already experienced or are highly susceptible to developing complications such as impaired eyesight or kidney problems.",
  subHeading2:
    "You're nearing the threshold where additional diabetes-related complications may arise.",
  subHeading3:
    "Your metabolism is currently in a favourable state. Maintain your dedication to good health by enrolling in our metabolic wellness program.",
  para1:
    "At Twin Health, we can help you in achieving Diabetes Reversal, and live a life without the burdens of diabetes.",
  para2:
    "Curious about how we can help? Click on 'Book FREE Consultation' below to schedule a free consultation and discover the ways we can support you.",
};

const QUIZ_LANGUAGES = [
  {
    name: "en",
    display: "English",
  },
  {
    name: "hi",
    display: "Hindi",
  },
  {
    name: "kn",
    display: "Kannada",
  },
  {
    name: "te",
    display: "Telugu",
  },
  {
    name: "ta",
    display: "Tamil",
  },
  {
    name: "ml",
    display: "Malayalam",
  },
];

const DOCTOR_SPECIALISATION = [
  {
    name: "pilot_diabetes",
    display: "Diabetes",
  },
  {
    name: "pilot_obesity",
    display: "Obesity",
  },
  {
    name: "pilot_pre_diabetes",
    display: "Pre-Diabetes",
  },
  {
    name: "pilot_metabolic_wellness",
    display: "Metabollic-Wellness",
  },
  {
    name: "pilot_hypertension",
    display: "Hyper-Tension",
  },
  {
    name: "pilot_pcod",
    display: "PCOD",
  },
];

const METABOLIC_ASSESSMENT_THANKYOUPAGE_VIDEO = {
  video1: "https://www.youtube.com/embed/sp0Y18LvUaE?autoplay=1&mute=1",
  video2: "https://www.youtube.com/embed/cR3gI9lQ06s?autoplay=1&mute=1",
  video3: "https://www.youtube.com/embed/8gVI0SGlXPI?autoplay=1&mute=1",
  video4: "https://www.youtube.com/embed/yDAU4xr2G2A?autoplay=1&mute=1",
  video5: "https://www.youtube.com/embed/Jw9vHIH92Hs?autoplay=1&mute=1",
};

const WBDT_TREATMENT_PLANS = [
  {
    treatmentType: "Diabetes",
    treatmentTypeValue: "wbdt_diabetes",
    plans: [
      {
        planType: "annual_plan",
        text: "Pay annually",
        offerPrice: 83333,
        planPrice: 75000,
        includedItems: [
          "CGM Patch 1 at start of the program",
          "Body Composition Monitor",
          "Blood test at start of the program",
        ],
        excludedItems: [
          "CGM Patch 2,3 at start of program",
          "Blood test on day 90, 180, 270, 360",
          "CGM Patch 4,5,6 on day 90, 180, 270 of program",
          "Ketone meter",
          "Smart watch",
        ],
      },
      // {
      //   planType: "installment_plan",
      //   text: "Pay in half yearly installments",
      //   offerPrice: 40000,
      //   planPrice: 39999,
      //   includedItems: [
      //     "Body Composition Monitor",
      //     "Blood test at start of the program",
      //   ],
      //   excludedItems: [
      //     "CGM Patch 1,2,3 at start of program",
      //     "Blood test on day 90, 180, 270, 360",
      //     "CGM Patch 4,5,6 on day 90, 180, 270 of program",
      //     "Ketone meter",
      //     "Smart watch",
      //   ],
      // },
      {
        planType: "quarterly_plan",
        text: "Pay in quarterly installments",
        offerPrice: 25000,
        planPrice: 22499,
        includedItems: ["Blood test at start of the program"],
        excludedItems: [
          "Body Composition Monitor",
          "CGM Patch 1,2,3 at start of program",
          "Blood test on day 90, 180, 270, 360",
          "CGM Patch 4,5,6 on day 90, 180, 270 of program",
          "Ketone meter",
          "Smart watch",
        ],
      },
    ],
  },
  {
    treatmentType: "Obesity",
    treatmentTypeValue: "wbdt_obesity",
    plans: [
      {
        planType: "annual_plan",
        text: "Pay annually",
        offerPrice: 83333,
        planPrice: 75000,
        includedItems: [
          "CGM Patch 1 at start of the program",
          "Body Composition Monitor",
          "Blood test at start of the program",
        ],
        excludedItems: ["Blood test on day 90, 180, 270, 360", "Smart watch"],
      },
      // {
      //   planType: "installment_plan",
      //   text: "Pay in half yearly installments",
      //   offerPrice: 45000,
      //   planPrice: 39999,
      //   includedItems: [
      //     "Body Composition Monitor",
      //     "Blood test at start of the program",
      //   ],
      //   excludedItems: [
      //     "CGM Patch 1 at start of program",
      //     "Blood test on day 90, 180, 270, 360",
      //     "Smart watch",
      //   ],
      // },
      {
        planType: "quarterly_plan",
        text: "Pay in quarterly installments",
        offerPrice: 25000,
        planPrice: 22499,
        includedItems: ["Blood test at start of the program"],
        excludedItems: [
          "Body Composition Monitor",
          "CGM Patch 1 at start of program",
          "Blood test on day 90, 180, 270, 360",
          "Smart watch",
        ],
      },
    ],
  },
  {
    treatmentType: "Hypertension",
    treatmentTypeValue: "wbdt_hypertension",
    plans: [
      {
        planType: "annual_plan",
        text: "Pay annually",
        offerPrice: 83333,
        planPrice: 75000,
        includedItems: [
          "CGM Patch 1 at start of the program",
          "Body Composition Monitor",
          "Blood test at start of the program",
        ],
        excludedItems: [
          "Blood test on day 90, 180, 270, 360",
          "BP Testing machine",
          "Smart watch",
        ],
      },
      // {
      //   planType: "installment_plan",
      //   text: "Pay in half yearly installments",
      //   offerPrice: 45000,
      //   planPrice: 39999,
      //   includedItems: [
      //     "Body Composition Monitor",
      //     "Blood test at start of the program",
      //   ],
      //   excludedItems: [
      //     "CGM Patch 1 at start of program",
      //     "Blood test on day 90, 180, 270, 360",
      //     "BP Testing machine",
      //     "Smart watch",
      //   ],
      // },
      {
        planType: "quarterly_plan",
        text: "Pay in quarterly installments",
        offerPrice: 25000,
        planPrice: 22499,
        includedItems: ["Blood test at start of the program"],
        excludedItems: [
          "Body Composition Monitor",
          "CGM Patch 1 at start of program",
          "Blood test on day 90, 180, 270, 360",
          "BP Testing machine",
          "Smart watch",
        ],
      },
    ],
  },
  {
    treatmentType: "Dyslipedimia",
    treatmentTypeValue: "wbdt_dyslipedimia",
    plans: [
      {
        planType: "annual_plan",
        text: "Pay annually",
        offerPrice: 83333,
        planPrice: 75000,
        includedItems: [
          "CGM Patch 1 at start of the program",
          "Body Composition Monitor",
          "Blood test at start of the program",
        ],
        excludedItems: ["Blood test on day 90, 180, 270, 360", "Smart watch"],
      },
      // {
      //   planType: "installment_plan",
      //   text: "Pay in half yearly installments",
      //   offerPrice: 45000,
      //   planPrice: 39999,
      //   includedItems: [
      //     "Body Composition Monitor",
      //     "Blood test at start of the program",
      //   ],
      //   excludedItems: [
      //     "CGM Patch 1 at start of program",
      //     "Blood test on day 90, 180, 270, 360",
      //     "Smart watch",
      //   ],
      // },
      {
        planType: "quarterly_plan",
        text: "Pay in quarterly installments",
        offerPrice: 25000,
        planPrice: 22499,
        includedItems: ["Blood test at start of the program"],
        excludedItems: [
          "Body Composition Monitor",
          "CGM Patch 1 at start of program",
          "Blood test on day 90, 180, 270, 360",
          "Smart watch",
        ],
      },
    ],
  },
  {
    treatmentType: "PCOD",
    treatmentTypeValue: "wbdt_pcod",
    plans: [
      {
        planType: "annual_plan",
        text: "Pay annually",
        offerPrice: 83333,
        planPrice: 75000,
        includedItems: [
          "CGM Patch 1 at start of the program",
          "Body Composition Monitor",
          "Blood test at start of the program",
        ],
        excludedItems: ["Blood test on day 90, 180, 270, 360", "Smart watch"],
      },
      // {
      //   planType: "installment_plan",
      //   text: "Pay in half yearly installments",
      //   offerPrice: 45000,
      //   planPrice: 39999,
      //   includedItems: [
      //     "Body Composition Monitor",
      //     "Blood test at start of the program",
      //   ],
      //   excludedItems: [
      //     "CGM Patch 1 at start of program",
      //     "Blood test on day 90, 180, 270, 360",
      //     "Smart watch",
      //   ],
      // },
      {
        planType: "quarterly_plan",
        text: "Pay in quarterly installments",
        offerPrice: 25000,
        planPrice: 22499,
        includedItems: ["Blood test at start of the program"],
        excludedItems: [
          "Body Composition Monitor",
          "CGM Patch 1 at start of program",
          "Blood test on day 90, 180, 270, 360",
          "Smart watch",
        ],
      },
    ],
  },
  {
    treatmentType: "MAFLD",
    treatmentTypeValue: "wbdt_mafld",
    plans: [
      {
        planType: "annual_plan",
        text: "Pay annually",
        offerPrice: 83333,
        planPrice: 75000,
        includedItems: [
          "CGM Patch 1 at start of the program",
          "Body Composition Monitor",
          "Blood test at start of the program",
        ],
        excludedItems: ["Blood test on day 90, 180, 270, 360", "Smart watch"],
      },
      // {
      //   planType: "installment_plan",
      //   text: "Pay in half yearly installments",
      //   offerPrice: 45000,
      //   planPrice: 39999,
      //   includedItems: [
      //     "Body Composition Monitor",
      //     "Blood test at start of the program",
      //   ],
      //   excludedItems: [
      //     "CGM Patch 1 at start of program",
      //     "Blood test on day 90, 180, 270, 360",
      //     "Smart watch",
      //   ],
      // },
      {
        planType: "quarterly_plan",
        text: "Pay in quarterly installments",
        offerPrice: 25000,
        planPrice: 22499,
        includedItems: ["Blood test at start of the program"],
        excludedItems: [
          "Body Composition Monitor",
          "CGM Patch 1 at start of program",
          "Blood test on day 90, 180, 270, 360",
          "Smart watch",
        ],
      },
    ],
  },
  {
    treatmentType: "Psoriasis",
    treatmentTypeValue: "wbdt_psoriasis",
    plans: [
      {
        planType: "annual_plan",
        text: "Pay annually",
        offerPrice: 83333,
        planPrice: 75000,
        includedItems: [
          "CGM Patch 1 at start of the program",
          "Body Composition Monitor",
          "Blood test at start of the program",
        ],
        excludedItems: ["Blood test on day 90, 180, 270, 360", "Smart watch"],
      },
      // {
      //   planType: "installment_plan",
      //   text: "Pay in half yearly installments",
      //   offerPrice: 45000,
      //   planPrice: 39999,
      //   includedItems: [
      //     "Body Composition Monitor",
      //     "Blood test at start of the program",
      //   ],
      //   excludedItems: [
      //     "CGM Patch 1 at start of program",
      //     "Blood test on day 90, 180, 270, 360",
      //     "Smart watch",
      //   ],
      // },
      {
        planType: "quarterly_plan",
        text: "Pay in quarterly installments",
        offerPrice: 25000,
        planPrice: 22499,
        includedItems: ["Blood test at start of the program"],
        excludedItems: [
          "Body Composition Monitor",
          "CGM Patch 1 at start of program",
          "Blood test on day 90, 180, 270, 360",
          "Smart watch",
        ],
      },
    ],
  },
];

const constants = {
  TWINQP_19750: "M1_QUARTERLY",
  TWINAP_69500: "M1_ANNUAL",
  STATES: STATES,
  GENDERS: GENDER,
  PAYMENT_PLANS: PAYMENT_PLANS,
  BOOK_PACK: BOOK_PACK,
  TWIN_STORE: TWIN_STORE,
  REVIEWS: REVIEWS,
  HOME_PAGE: HOME_PAGE,
  BOOKPACK_LANGUAGES: BOOKPACK_LANGUAGES,
  BOOKPACK_ERROR_MESSAGES: BOOKPACK_ERROR_MESSAGES,
  BOOKPACK_PINCODE_MESSAGES: BOOKPACK_PINCODE_MESSAGES,
  BOOKPACK_CATEGORIES: BOOKPACK_CATEGORIES,
  DOCTOR_REFERRAL: DOCTOR_REFERRAL,
  CSV_UPLOAD_VIDEOS: CSV_UPLOAD_VIDEOS,
  CSV_UPLOAD_DOWNLOAD_LINKS: CSV_UPLOAD_DOWNLOAD_LINKS,
  GET_IN_TOUCH_LANGUAGES,
  GET_IN_TOUCH_TIMES,
  HOME_PAGE_V2: HOME_PAGE_V2,
  METABOLIC_ASSESSMENT_QUESTIONS: METABOLIC_ASSESSMENT_QUESTIONS,
  QUIZ_HEIGHT: QUIZ_HEIGHT,
  METABOLIC_ASSESSMENT_RESULTPAGE_PARA: METABOLIC_ASSESSMENT_RESULTPAGE_PARA,
  QUIZ_LANGUAGES: QUIZ_LANGUAGES,
  DOCTOR_SPECIALISATION: DOCTOR_SPECIALISATION,
  METABOLIC_ASSESSMENT_THANKYOUPAGE_VIDEO:
    METABOLIC_ASSESSMENT_THANKYOUPAGE_VIDEO,
  WBDT_TREATMENT_PLANS: WBDT_TREATMENT_PLANS,
};

export default constants;
