import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// styles
import * as styles from "./FooterV1.module.css";

const Divider = styled.div`
  border-bottom: 1px solid #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 0.7;
`;

const FooterTerms = props => {
  // props.isHome -> for adding a botoom space in mobile
  return (
    <>
      <div className={`${styles.footerWrapper}`}>
        <Divider />
        <div
          className={`container ${styles.footerContainer} ${
            props.isHome && styles.footerContainerBottom
          }`}
        >
          <div className="row mt-4">
            <div className="col-12 text-center">
              {/* <Link
                to="/terms"
                className={`navLink me-4 ${styles.footerTerms}`}
              >
                Privacy Policy
              </Link> */}
              <Link to="/terms" className={`navLink ${styles.footerTerms}`}>
                Terms Of Use
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterTerms;
