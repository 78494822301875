const config = {
  APP_ENV: process.env.GATSBY_APP_ENV,
  DX_API_BASE_URL: process.env.GATSBY_DX_API_BASE_URL,
  LEAD_SQUARED_URL: process.env.GATSBY_LEAD_SQUARED_URL,
  LEAD_SQUARED_ACCESS_KEY: process.env.GATSBY_LEAD_SQUARED_ACCESS_KEY,
  LEAD_SQUARED_SECRET_KEY: process.env.GATSBY_LEAD_SQUARED_SECRET_KEY,
  CLOUDINARY_NAME: process.env.GATSBY_CLOUDINARY_NAME,
  JWT_TOKEN: process.env.GATSBY_JWT_TOKEN,
  CLEVERTAP_ID: process.env.GATSBY_CLEVERTAP_ID,
  RAZORPAY_KEY: process.env.GATSBY_RAZORPAY_KEY,
  NOCODE_API_BASE_URL: process.env.GATSBY_NOCODE_API_BASE_URL,
  WEBINAR_SHEET_ID: process.env.GATSBY_WEBINAR_SHEET_ID,
  RAZORPAY_LINK_LP_YT: process.env.GATSBY_RAZORPAY_LINK_LP_YT,
  RAZORPAY_LINK_LP: process.env.GATSBY_RAZORPAY_LINK_LP,
  RAZORPAY_LINK_LP_YT_2: process.env.GATSBY_RAZORPAY_LINK_LP_YT_2,
  RAZORPAY_LINK_LP_FB: process.env.GATSBY_RAZORPAY_LINK_LP_FB,
  RAZORPAY_LINK_LP_YT_3: process.env.GATSBY_RAZORPAY_LINK_LP_YT_3,
  RAZORPAY_LINK_LP_NEW: process.env.GATSBY_RAZORPAY_LINK_LP_NEW,
  ICAP_PLATFORM_URL: process.env.GATSBY_ICAP_PLATFORM_URL,
  MIXPANEL_V2: process.env.GATSBY_MIXPANEL_V2,
  DX_UI_URL: process.env.GATSBY_DX_UI_URL,
};

export default config;
