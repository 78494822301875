import React from "react";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

// constants
import constants from "../../constants";

// forms
import FreeConsultForm from "./FreeConsultForm";

// styles
import * as styles from "./Header.module.css";

const FreeConsultModal = props => {
  const { contact } = constants.HOME_PAGE;
  const callingPhone = `tel:+91${contact.phone}`;

  const handleUpdate = data => {
    if (data === "CALL_BACK") {
      toast(
        "Your request has been successfully submitted, Our team will get back to you within 24 hrs",
        {
          toastId: "consultMobileToast",
        }
      );
    } else {
      window.open(callingPhone);
    }
    props.onHide();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        bsPrefix="right_modal modal"
      >
        <Modal.Header closeButton className={styles.closeBtn}>
          <div className="d-block d-lg-none d-xl-none d-xxl-none">&nbsp;</div>
        </Modal.Header>
        <Modal.Body className="ps-4 pe-4">
          <div className={`mt-3 ${styles.freeConsult}`}>
            <h2>Get free consult</h2>
            <p>
              Please fill the following details, Our <br />
              team will get back to you within 24 hrs
            </p>
            <div className={`${styles.consultDivider}`}></div>
            <FreeConsultForm handleUpdate={handleUpdate} />
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer
        containerId="consultToast"
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        draggable={false}
        toastClassName={styles.toastClassName}
        style={{ top: "0px", right: "0px" }}
      />
    </>
  );
};

export default FreeConsultModal;
