import React from "react";
import { Link } from "gatsby";
import Modal from "react-bootstrap/Modal";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// constants
import constants from "../../constants";

// ct
import { sendToCT } from "../../services/Clevertap";

// styles
import * as styles from "./Header.module.css";

const TogglerModal = props => {
  const { contact, socialLinks } = constants.HOME_PAGE;
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        bsPrefix="right_modal modal"
        dialogClassName={styles.toggleHeight}
      >
        <Modal.Header closeButton className={styles.closeBtn}>
          <div className="d-block d-lg-none d-xl-none d-xxl-none">
            <StaticImage
              src="../../images/twin-logo/twinLogo2_2024.png"
              alt="Banner"
              placeholder="blurred"
              className={styles.toogleLogoImage}
              quality={90}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container h-100">
            <div className="row h-100 align-items-end">
              <div className="col">
                <div>
                  <ul
                    className={`list-unstyled ${styles.toggleNavList}`}
                    onClick={props.onHide}
                    role="presentation"
                  >
                    {/* <li>
                      <Link
                        to="/wbdt-care-plan-pricing"
                        className="navLink"
                        onClick={() =>
                          sendToCT("Enroll_now_Navbar_Clicked", {
                            action: "Clicks on Program Pricing Side Navbar Cta",
                          })
                        }
                      >
                        Program Pricing
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/wbdt-care-plan"
                        className="navLink"
                        onClick={() =>
                          sendToCT("Enroll_now_Navbar_Clicked", {
                            action: "Clicks on Enroll Now Side Navbar Cta",
                          })
                        }
                      >
                        Enroll Now
                      </Link>
                    </li>
                    <li>
                      <AnchorLink
                        to="/#about-twin"
                        className="navLink"
                        onClick={() =>
                          sendToCT("About_Twin_Navbar_Clicked", {
                            action: "Clicks on About Twin Side Navbar Cta",
                          })
                        }
                      >
                        About Twin
                      </AnchorLink>
                    </li>
                    <li>
                      <Link
                        to="/about-us"
                        className="navLink"
                        onClick={() =>
                          sendToCT("Our_Team_Navbar_Clicked", {
                            action: "Clicks on Our Team Side Navbar Cta",
                          })
                        }
                      >
                        Our Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms"
                        className="navLink"
                        onClick={() =>
                          sendToCT("Terms_clicked", {
                            action: "Clicks on Terms of use",
                          })
                        }
                      >
                        Terms of use
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.toggleContacts}`}>
                  <ul className={`list-unstyled ${styles.toggleNavList2}`}>
                    <li>
                      <a
                        href={`mailto:${contact.email}`}
                        className="navLink"
                        onClick={() =>
                          sendToCT("Email_clicked", {
                            action: "Clicks on Email address in hamburger menu",
                          })
                        }
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <div className={`${styles.emailIcon}`}>
                            <span
                              className="icon-tablet-1"
                              aria-hidden="true"
                            ></span>
                          </div>
                          <div className={`${styles.emailText}`}>
                            <span>{contact.email}</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`tel:+91${contact.phone}`}
                        className="navLink"
                        onClick={() =>
                          sendToCT("Phone_number_clicked", {
                            action: "Clicks on Phone number in hamburger menu",
                          })
                        }
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <div className={`${styles.callIcon}`}>
                            <span
                              className="icon-call"
                              aria-hidden="true"
                            ></span>
                          </div>
                          <div className={`${styles.callText}`}>
                            <div>{contact.phone}</div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div>
                  <ul
                    className={`list-inline d-flex flex-row ${styles.toggleSocialList}`}
                  >
                    <li>
                      <a
                        className="navLink"
                        href={socialLinks.facebook}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          sendToCT("facebook_clicked", {
                            action: "Clicks on facebook icon",
                          })
                        }
                      >
                        <div>
                          <i className="icon-facebook" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className="navLink"
                        href="#"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div>
                          <i className="icon-twitter" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="navLink"
                        href={socialLinks.linkedin}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          sendToCT("Linkedin_clicked", {
                            action: "Clicks on Linkedin icon",
                          })
                        }
                      >
                        <div>
                          <i className="icon-linkedin" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        className="navLink"
                        href={socialLinks.instagram}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          sendToCT("instagram_clicked", {
                            action: "Clicks on Instagram icon",
                          })
                        }
                      >
                        <div>
                          <i className="icon-instagram" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a
                        className="navLink"
                        href={socialLinks.youtube}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          sendToCT("Youtube_clicked", {
                            action: "Clicks on Youtube icon",
                          })
                        }
                      >
                        <div>
                          <i className="icon-youtube" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TogglerModal;
