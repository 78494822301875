import React, { useState } from "react";
import { useForm } from "react-hook-form";

// ct
import { sendToCT } from "../../services/Clevertap";

// services
import { sendToLSQ } from "../../services/LeadSquared";

// components
import Button from "../Button/Button";

// styles
import * as styles from "./Header.module.css";

const FreeConsultForm = props => {
  const [isLoadingCallNow, setLoadingCallNow] = useState(false);
  const [isDisableCallNow, setDisableCallNow] = useState(false);

  const [isLoadingCallBack, setLoadingCallBack] = useState(false);
  const [isDisableCallBack, setDisableCallBack] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async data => {
    // console.log(data);

    let mxCTA = data.submitAction === "CALL_NOW" ? "Call now" : "Call back";
    let leadSquaredParams = {
      FirstName: data.name,
      Phone: data.phone,
      EmailAddress: data.email,
      mx_Website_CTA: mxCTA,
      mx_Latest_Source: "New Website",
    };

    try {
      const response = await sendToLSQ(leadSquaredParams);
      localStorage.setItem("relatedId", response.data.Message.RelatedId);
      setLoadingCallNow(false);
      setDisableCallNow(false);
      setLoadingCallBack(false);
      setDisableCallBack(false);
      props.handleUpdate(data.submitAction);
    } catch (error) {
      console.log("LSQ error", error);
      setLoadingCallNow(false);
      setDisableCallNow(false);
      setLoadingCallBack(false);
      setDisableCallBack(false);
      props.handleUpdate(data.submitAction);
    }
  };

  const onSubmitCallNow = handleSubmit(data => {
    sendToCT("Call_now_clicked", {
      action: "For call now CTA",
    });

    data.submitAction = "CALL_NOW";
    // console.log("CALL_NOW", data);
    setLoadingCallNow(true);
    setDisableCallBack(true);
    onSubmit(data);
  });

  const onSubmitCallBack = handleSubmit(data => {
    sendToCT("Call_later_clicked", {
      action: "For request a call back later CTA",
    });

    data.submitAction = "CALL_BACK";
    // console.log("CALL_BACK", data);
    setLoadingCallBack(true);
    setDisableCallNow(true);
    onSubmit(data);
  });

  return (
    <>
      <form name="freeConsultForm" onSubmit={onSubmitCallNow}>
        <div className="row">
          <div className="col-md-12">
            <div className={`form-group ${styles.formInput}`}>
              <label htmlFor="name">Name</label>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                placeholder="Full name"
                id="name"
                type="text"
                {...register("name", {
                  required: "Please enter your name",
                  pattern: {
                    value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                    message: "Please enter your valid name",
                  },
                  maxLength: { value: 50, message: "Name is too long" },
                })}
              />
              {errors.name && (
                <span className="invalid-feedback">{errors.name.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className={`form-group ${styles.formInput}`}>
              <label htmlFor="phone">Phone</label>
              <input
                className={`form-control letterSp1
                  ${errors.phone ? "is-invalid" : ""}
                `}
                placeholder="Contact number"
                id="phone"
                type="text"
                maxLength="10"
                inputMode="numeric"
                {...register("phone", {
                  required: "Please enter your mobile number",
                  pattern: {
                    value: /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                    message: "Please enter your valid phone number",
                  },
                  maxLength: { value: 10, message: "Invalid phone number" },
                })}
              />
              {errors.phone && (
                <span className="invalid-feedback">{errors.phone.message}</span>
              )}
            </div>
          </div>

          <div className="col-md-12 mt-3">
            <div className={`form-group ${styles.formInput}`}>
              <label htmlFor="email">Email</label>
              <input
                className={
                  errors.email ? "form-control is-invalid" : "form-control"
                }
                placeholder="Email id"
                id="email"
                type="email"
                {...register("email", {
                  required: "Please enter your email",
                  pattern: {
                    value:
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                    message: "Please enter your valid email",
                  },
                })}
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>

          <div className="col-md-12 mt-4 pt-3">
            <Button
              type="submit"
              text="CALL NOW"
              btnClass="w-100"
              isLoading={isLoadingCallNow}
              isDisable={isDisableCallNow}
            />
          </div>
          <div className="col-md-12 mt-3">
            <Button
              type="button"
              text="Request a call back"
              btnClass="w-100"
              onClick={onSubmitCallBack}
              isLoading={isLoadingCallBack}
              isDisable={isDisableCallBack}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default FreeConsultForm;
