import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import styled from "styled-components";
import { useScrollRestoration } from "gatsby";

// seo
import Seo from "../Seo";

// components
import Header from "../Header/Header";
import Footer from "../Footer/FooterV1";

// context
// import { ContextProviderComponent } from "./Context";

// images
import favicon from "../../images/twin-logo/favicon_2024.png";

const MainWrapper = styled.div`
  min-height: 100%;
`;

const BothWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 768px;
  margin: auto;
`;

const AppWrapper = styled.div`
  min-height: 100%;
`;

const Layout = ({ children, location }) => {
  const [showAppWrapper, setShowAppWrapper] = useState(false);

  const { appHeader } = queryString.parse(location.search);

  const mainScrollRestoration = useScrollRestoration(`page-component-main`);
  const bothScrollRestoration = useScrollRestoration(
    `page-component-main-both`
  );
  const appScrollRestoration = useScrollRestoration(`page-component-main-app`);

  const isAppLayout = () => {
    // console.log("location", location);
    if (location.pathname.match(`/app/`)) {
      return true;
    } else if (location.pathname.match(`cgm-status`)) {
      return true;
    } else if (location.pathname.match(`webinar/reversal`)) {
      return true;
    } else if (location.pathname.match(`webinar/webinar-thanking`)) {
      return true;
    } else if (location.pathname.match(`diabetes-quiz`)) {
      return true;
    } else if (location.pathname.match(`referral-quiz`)) {
      return true;
    } else if (location.pathname.match(`risk-quiz`)) {
      return true;
    } else if (location.pathname.match(`reversal-webinar`)) {
      return true;
    } else if (location.pathname.match(`reversal-thanking`)) {
      return true;
    } else if (location.pathname.match(`payment-status`)) {
      return true;
    } else if (location.pathname.match(`doctor-consult-successful`)) {
      return true;
    } else if (location.pathname.match(`doctor-referral-login`)) {
      return true;
    } else if (location.pathname.match(`doctor-referral-home`)) {
      return true;
    } else if (location.pathname.match(`csv-login`)) {
      return true;
    } else if (location.pathname.match(`csv-upload`)) {
      return true;
    } else if (location.pathname.match(`twin-consultation`)) {
      return true;
    } else if (location.pathname.match(`metabolism-assessment`)) {
      return true;
    } else if (location.pathname.match(`/doctor/doctor-portal`)) {
      return true;
    } else if (location.pathname.match(`/wbdt-care-remarketing-plan`)) {
      return true;
    } else if (location.pathname.match(`/doctor/`)) {
      return true;
    } else if (location.pathname.match(`/metabolism-assesment-ty/`)) {
      return true;
    } else if (location.pathname.match(`/quiz/diabetes-lp`)) {
      return true;
    } else if (location.pathname.match(`/quiz/diabetes-result`)) {
      return true;
    } else if (location.pathname.match(`/quiz/diabetes-ty`)) {
      return true;
    } else if (location.pathname.match(`/wbdt-care-plan-option`)) {
      return true;
    }

    return false;
  };

  // for home
  let isHome = false;
  if (location.pathname === "/") {
    isHome = true;
  }

  // for hide footer
  let showFooter = false;
  if (location.pathname === "/") {
    showFooter = true;
  } else if (location.pathname.match(`about-us`)) {
    showFooter = true;
  }

  useEffect(() => {
    if (appHeader && (appHeader === "mobile" || appHeader === "no")) {
      setShowAppWrapper(true);
    }
  }, [appHeader]);

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>

      {/* default seo */}
      <Seo
        title="Reverse Diabetes"
        description="Twin reverse's type 2 diabetes using AI based precision treatment. Learn how 97% reduced blood sugar, medicines & 98% got rid of all symptoms."
      />

      {isAppLayout() ? (
        <AppWrapper {...appScrollRestoration}>{children}</AppWrapper>
      ) : showAppWrapper ? (
        <BothWrapper {...bothScrollRestoration}>{children}</BothWrapper>
      ) : (
        <>
          <MainWrapper {...mainScrollRestoration}>
            <Header location={location} isHome={isHome} />
            {children}
            {showFooter && <Footer isHome={true}/>}
          </MainWrapper>
        </>
      )}
    </>
  );
};

export default Layout;
