import { getUtmDataFromLS } from "../../utils";
import config from "../../config";
import axios from "axios";

export const sendToLSQ = async params => {
  const utmData = getUtmDataFromLS();
  let LSQSchema = [
    {
      Attribute: "FirstName",
      Value: "",
    },
    {
      Attribute: "LastName",
      Value: "",
    },
    {
      Attribute: "Phone",
      Value: "",
    },
    {
      Attribute: "EmailAddress",
      Value: "",
    },
    {
      Attribute: "mx_Website_CTA",
      Value: "",
    },
    {
      Attribute: "mx_Webinar_Lead_Captured",
      Value: "",
    },
    {
      Attribute: "mx_Webinar_datetime",
      Value: "",
    },
    {
      Attribute: "Notes",
      Value: "",
    },
    {
      Attribute: "mx_Latest_Source",
      Value: utmData.utm_source,
    },
    {
      Attribute: "mx_Latest_medium",
      Value: utmData.utm_medium,
    },
    {
      Attribute: "mx_Latest_campaign",
      Value: utmData.utm_campaign,
    },
    {
      Attribute: "mx_Latest_term",
      Value: utmData.utm_term,
    },
    {
      Attribute: "mx_Latest_content",
      Value: utmData.utm_content,
    },
    {
      Attribute: "SearchBy",
      Value: "",
    },
    {
      Attribute: "mx_Counselling_Date_and_Time",
      Value: "",
    },
    {
      Attribute: "mx_quiz_age",
      Value: "",
    },
    {
      Attribute: "mx_quiz_gender",
      Value: "",
    },
    {
      Attribute: "mx_quiz_hba1c",
      Value: "",
    },
    {
      Attribute: "mx_quiz_medicine",
      Value: "",
    },
    {
      Attribute: "mx_quiz_sleep_hrs",
      Value: "",
    },
    {
      Attribute: "mx_quiz_weight",
      Value: "",
    },
    {
      Attribute: "mx_Product_id",
      Value: "",
    },
    {
      Attribute: "mx_Product_type",
      Value: "",
    },
    {
      Attribute: "Referred_Doctor_Name",
      Value: "",
    },
    {
      Attribute: "mx_City",
      Value: "",
    },
    {
      Attribute: "mx_Language",
      Value: "",
    },
    {
      Attribute: "mx_Preferred_time_of_call",
      Value: "",
    },
    {
      Attribute: "mx_Height",
      Value: "",
    },
    {
      Attribute: "mx_Diabetes_Spend",
      Value: "",
    },
    {
      Attribute: "mx_How_often_you_smoke",
      Value: "",
    },
    {
      Attribute: "mx_How_often_you_consume_alcohol",
      Value: "",
    },
    // This field is used to store metabolism assessment quiz ques value i.e What is your health care expense for the month?
    {
      Attribute: "mx_quiz_medicines",
      Value: "",
    },
    {
      Attribute: "mx_Pack_Amount",
      Value: "",
    },
    {
      Attribute: "mx_capture_the_metabolic_score",
      Value: "",
    },
    {
      Attribute: "mx_capture_BMI",
      Value: "",
    },
    // For Doctor Landing Page
    {
      Attribute: "mx_Doc_Channel_Doctor",
      Value: "",
    },
    {
      Attribute: "mx_Referral_Doctor_IDs",
      Value: "",
    },
    {
      Attribute: "Source",
      Value: "",
    },
    {
      Attribute: "mx_Device_Mobile_Desktop",
      Value: "",
    },
    {
      Attribute: "mx_msc_Operating_System",
      Value: "",
    },
    {
      Attribute: "mx_capture_calendly_booking_timedate",
      Value: "",
    },
  ];

  LSQSchema.forEach(schema => {
    let attribute = schema.Attribute;
    if (params.hasOwnProperty(attribute)) {
      if (attribute === "mx_Latest_Source") {
        if (schema.Value === "") {
          schema.Value = params[attribute];
        }
      } else {
        schema.Value = params[attribute];
      }
    }
  });
  // console.log("LSQSchema", LSQSchema);
  const leadSquaredOptions = {
    method: "post",
    url: config.LEAD_SQUARED_URL,
    // params: {
    //   accessKey: config.LEAD_SQUARED_ACCESS_KEY,
    //   secretKey: config.LEAD_SQUARED_SECRET_KEY,
    // },
    data: LSQSchema,
  };

  try {
    const lsqResponse = await axios(leadSquaredOptions);
    return lsqResponse;
  } catch (error) {
    console.log("lsq server error", error);
    // handle too many request
    // status code: 429
    // {
    //   "Status":"Error",
    //   "ExceptionType":"MXAPIRateLimitExceededException",
    //   "ExceptionMessage":"API calls exceeded the limit of 10 in 5 second(s)"
    // }

    if (error.response.status === 429 && error.response.data) {
      sendToLsqLogs(params);
    }

    // if (error.response) {
    //   throw new Error(error.response.data.error);
    // } else {
    //   // console.error("server err:", error.message);
    //   throw new Error(error.message);
    // }
    return false;
  }
};

const sendToLsqLogs = async params => {
  try {
    const lsqLogsOptions = {
      method: "post",
      url: "https://7ltr1ugjpl.execute-api.us-west-2.amazonaws.com/dx-lsq-logs-ingress",
      data: params,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(lsqLogsOptions);
  } catch (error) {
    console.log("lsq logs: server error", error);
  }
};
