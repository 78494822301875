import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ToastContainer, toast } from "react-toastify";

// forms
import FreeConsultForm from "./FreeConsultForm";

// styles
import * as styles from "./Header.module.css";

const FreeConsultMobileModal = props => {
  const handleUpdate = data => {
    if (data === "CALL_BACK") {
      toast(
        "Your request has been successfully submitted, Our team will get back to you within 24 hrs",
        {
          toastId: "consultMobileToast",
        }
      );
    } else {
      window.open("tel:+919005008946");
    }

    props.onHide();
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        onHide={props.onHide}
        placement="bottom"
        style={{
          height: "77vh",
          borderRadius: "10px 10px 0px 0px",
          background: "#f1f1ee",
        }}
      >
        <Offcanvas.Body>
          <div
            className={`${styles.freeConsultClose}`}
            role="presentation"
            onClick={props.onHide}
          ></div>
          <div className={`mt-3 ${styles.freeConsult}`}>
            <h2>Get free consult</h2>
            <p className="mt-2 mb-0">
              Please fill the following details, Our <br />
              team will get back to you within 24 hrs
            </p>
            <div className={`${styles.consultDivider}`}></div>
            <FreeConsultForm handleUpdate={handleUpdate} />
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <ToastContainer
        containerId="consultMobileToast"
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        draggable={false}
        toastClassName={styles.toastClassName}
        style={{ top: "0px", right: "0px" }}
      />
    </>
  );
};

export default FreeConsultMobileModal;
