// extracted by mini-css-extract-plugin
export var footerCall = "FooterV1-module--footerCall--pQkxy";
export var footerCall_image = "FooterV1-module--footerCall_image--2vlfL";
export var footerContainer = "FooterV1-module--footerContainer--YE+Y3";
export var footerContainerBottom = "FooterV1-module--footerContainerBottom--9lQYF";
export var footerContainerTop = "FooterV1-module--footerContainerTop--6dZss";
export var footerFollowUs = "FooterV1-module--footerFollowUs--DgZv0";
export var footerSocialIcon = "FooterV1-module--footerSocialIcon--oGIgo";
export var footerTerms = "FooterV1-module--footerTerms--Jk0vt";
export var footerWrapper = "FooterV1-module--footerWrapper--QVFDX";
export var footerWrapperTop = "FooterV1-module--footerWrapperTop--9-x-Q";
export var newFooter__container = "FooterV1-module--newFooter__container--eGvib";
export var newFooter__link = "FooterV1-module--newFooter__link--B90nf";