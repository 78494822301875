import { useLayoutEffect, useState, useCallback } from "react";

const StickyHeader = (defaultSticky = false) => {
  const [isSticky, setIsSticky] = useState(defaultSticky);
  const [scrollHeight, setScrollHeight] = useState(0);

  const toggleSticky = useCallback(
    ({ scrollY }) => {
      //   console.log("scrollY", scrollY);
      if (scrollY > 350) {
        if (!isSticky) {
          setIsSticky(true);
          setScrollHeight(350);
        }
      } else {
        if (isSticky) {
          setIsSticky(false);
          setScrollHeight(350);
        }
      }
    },
    [isSticky]
  );

  useLayoutEffect(() => {
    const handleScroll = () => {
      toggleSticky(window);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleSticky]);

  return { isSticky, scrollHeight };
};

export default StickyHeader;
