import clevertap from "clevertap-web-sdk";

//config
import config from "../../config";

//utils
import { getTimeStamp } from "../../utils";

const initCT = () => {
  if (typeof window !== "undefined") {
    clevertap.init(config.CLEVERTAP_ID);
  }
};

// push notification
export const pushNotificationWebsiteLaunch = () => {
  initCT();
  if (typeof window !== "undefined") {
    return clevertap.notifications.push({
      askAgainTimeInSeconds: 2,
      titleText: "Welcome to Twin",
      bodyText: "Click okay to send you notification by us",
      okButtonText: "Okay",
      rejectButtonText: "No",
      okButtonColor: "#00FF00",
    });
  }
};

// send to ct
export const sendToCT = (eventName = "Website Launched", payload = {}) => {
  initCT();
  const today = getTimeStamp();
  payload.clickedAt = today;
  if (typeof window !== "undefined") {
    return clevertap.event.push(eventName, payload);
  }
};

// system event -> when website launch
export const websiteLaunchEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Website_Launched", launchAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Website Launched", payload);
  }
};

// website homepage header button events
export const CTA1Event = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "CTA_1 Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("CTA_1 Clicked", payload);
  }
};

export const CTA2Event = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "CTA2 Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("CTA_2 Clicked", payload);
  }
};

export const offerStripEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Offer Strip Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Offer Strip Clicked", payload);
  }
};

// Website homepage top/first carousel events
export const caraouselEvent = id => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Carousel_" + id + " Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Caraousel_" + id + " Clicked", payload);
  }
};

// Website Homepage Webinar Strip Event
export const offerStrip2Event = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Offer Strip_2 Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Offer Strip_2 Clicked", payload);
  }
};

// Diabetes Quiz Events
export const diabetesQuizVisitEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Calculate_Reversal Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Diabetes Quiz Visited", payload);
  }
};

export const diabetesQuizStartEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Get_Started Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Diabetes Quiz Initiated", payload);
  }
};

export const diabetesQuizSuccessEvent = (
  name,
  num,
  val1,
  val2,
  val3,
  val4,
  val5,
  val6
) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    name: name,
    phoneNumber: num,
    gender: val1,
    age: val2,
    weight: val3,
    numMeds: val4,
    sleepHours: val5,
    hba1c: val6,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Diabetes Quiz Success", payload);
  }
};

// Risk Quiz Events
export const riskQuizVisitEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Calculate_Reversal Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Risk Quiz Visited", payload);
  }
};

export const riskQuizStartEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Get_Started Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Risk Quiz Initiated", payload);
  }
};

export const riskQuizSuccessEvent = (
  name,
  num,
  score,
  val1,
  val2,
  val3,
  val4,
  val5,
  val6,
  val7,
  val8
) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    name: name,
    phoneNumber: num,
    totalScore: score,
    gender: val1,
    age: val2,
    bmi: val3,
    waist: val4,
    physicalActivity: val5,
    diabetesHistory: val6,
    sleepPattern: val7,
    eatJunkFood: val8,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Risk Quiz Success", payload);
  }
};

// Hamburger menu events
export const hamburgerInitiatedEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Free_Consultation Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Hamburger Consultation Initiated", payload);
  }
};

export const hamburgerCallNowEvent = (name, num) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Call_Now Clicked",
    name: name,
    phoneNumber: num,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Hamburger Consultation Call Now", payload);
  }
};

export const hamburgerCallLaterEvent = (name, num) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Call_Later Clicked",
    name: name,
    phoneNumber: num,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Hamburger Consultation Call Later", payload);
  }
};

// Mobile screen Pop up events
export const popUpClosedEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Pop_Up_Close_Icon Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Pop Up Closed", payload);
  }
};

export const popUpCtaEvent = (name, num) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Pop_Up_CTA Clicked",
    name: name,
    phoneNumber: num,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Pop Up CTA", payload);
  }
};

// Footer Pricing Events
export const pricingEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = { action: "Pricing_Footer Clicked", clickedAt: today };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Pricing Viewed", payload);
  }
};

export const planDetailsEvent = () => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Whats_Included_Book_Pack Clicked",
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Plan Details Viewed", payload);
  }
};

// Book Pack Events
export const enrollmentInitiatedEvent = (
  name,
  num,
  email,
  pin,
  coupon,
  paymentPlan
) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Book_Pack_Buy_Now Clicked",
    name: name,
    phone: num,
    email: email,
    pincode: pin,
    coupon: coupon,
    paymnetPlan: paymentPlan,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Enrollment Initiated", payload);
  }
};

// Book Pack Webinar Sales Events
export const enrollmentInitiatedWebinarSalesEvent = (
  name,
  num,
  email,
  pin,
  coupon,
  paymentPlan
) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Book_Pack_Webinar_Sales_Buy_Now Clicked",
    name: name,
    phone: num,
    email: email,
    pincode: pin,
    coupon: coupon,
    paymnetPlan: paymentPlan,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Enrollment Initiated", payload);
  }
};

// Book Pack Referral Events
export const enrollmentInitiatedReferralEvent = (
  name,
  num,
  email,
  pin,
  coupon,
  paymentPlan
) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Book_Pack_Referral_Buy_Now Clicked",
    name: name,
    phone: num,
    email: email,
    pincode: pin,
    coupon: coupon,
    paymnetPlan: paymentPlan,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Enrollment Initiated", payload);
  }
};

export const enrollmentSuccessEvent = (
  clientId,
  name,
  num,
  email,
  gen,
  dob,
  add,
  amount,
  id,
  pin,
  city
) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Payment_Success_Submit Clicked",
    clientId: clientId,
    name: name,
    phone: num,
    email: email,
    gender: gen,
    dob: dob,
    address: add,
    amount: amount,
    invoiceId: id,
    pincode: pin,
    city: city,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Enrollment Successfull", payload);
  }
};

// App for Dx Events
export const webinarCarouselEvent = clientId => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Webinar_Carousel_DxApp Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("DxApp_Webinar_Carousel", payload);
  }
};

export const learnMoreEvent = clientId => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Learn_More_DxApp Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Learn More", payload);
  }
};

export const enrollNowEvent = clientId => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Enroll_Now_DxApp Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Enroll Now", payload);
  }
};

export const videoEvent = (clientId, id) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "DxApp_Video_" + id + " Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("DxApp_Video_" + id + " Clicked", payload);
  }
};

export const bookPackEvent = clientId => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Book_Pack_DxApp Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Book Pack", payload);
  }
};

export const planDetailsDxAppEvent = clientId => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Whats_Included_DxApp Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Plan Details Viewed", payload);
  }
};

export const allBlogsEvent = clientId => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Blog_See_All_DxApp Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Blog See All", payload);
  }
};

export const singleBlogEvent = (clientId, id) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "DxApp_Blog_" + id + " Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("DxApp_Blog_" + id + " Clicked", payload);
  }
};

export const quizVisitedEvent = clientId => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Calculate_Reversal_Date_DxApp Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Quiz Visited-DxApp", payload);
  }
};

export const webinarVisitedEvent = clientId => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Register_Now_Webinar_DxApp Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Webinar Visited-DxApp", payload);
  }
};

export const logoutEvent = clientId => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Logout_DxApp Clicked",
    clientId: clientId,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Logout", payload);
  }
};

// Webinar Events
export const webinarRegInitiatedEvent = (
  clientId,
  name,
  email,
  phone,
  slot,
  coupon
) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Webinar_Register_CTA Clicked",
    clientId: clientId,
    name: name,
    email: email,
    phone: phone,
    webinarTimeSlot: slot,
    couponCode: coupon,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Webinar Registration Initiated", payload);
  }
};

export const webinarRegSuccessEvent = (
  clientId,
  name,
  email,
  phone,
  slot,
  coupon
) => {
  initCT();
  const today = getTimeStamp();
  const payload = {
    action: "Webinar_Payment_Success Clicked",
    clientId: clientId,
    name: name,
    email: email,
    phone: phone,
    webinarTimeSlot: slot,
    couponCode: coupon,
    clickedAt: today,
  };
  if (typeof window !== "undefined") {
    return clevertap.event.push("Webinar Registration Successful", payload);
  }
};
