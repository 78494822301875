import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";

// hooks
// import { useHomeQuery } from "../../hooks/useHomeQuery";

// ct
import { sendToCT } from "../../services/Clevertap";

// modals
import TogglerModal from "./TogglerModal";
import FreeConsultModal from "./FreeConsultModal";
import FreeConsultMobileModal from "./FreeConsultMobileModal";

// sticky header
import StickyHeader from "./StickyHeader";

// utils
import { saveUTM } from "../../utils";

// styles
import * as styles from "./Header.module.css";

const Header = props => {
  const [showTogglerModal, setShowTogglerModal] = useState(false);
  const [showFreeConsultModal, setShowFreeConsultModal] = useState(false);
  const [showFreeConsultMobileModal, setShowFreeConsultMobileModal] =
    useState(false);

  const { isSticky, scrollHeight } = StickyHeader();

  // const bannerPayload = useHomeQuery("banner");

  // console.log("location", props.location);

  useEffect(() => {
    // save utm to local storage
    if (props.location.search) {
      saveUTM(props.location.search);
    }
  }, [props.location.search]);

  return (
    <>
      <div
        className={`${styles.headerWrapper} ${
          !props.isHome && "whiteBgWrapper"
        } `}
        id="topHeader"
      >
        <nav
          className={`navbar navbar-expand-lg fixed-top navbar-light ${
            styles.navBar
          } ${!props.isHome && "darkBlueBg"} 
          ${props.isHome && scrollHeight && "whiteBgFade"}
          ${props.isHome && isSticky && "darkBlueBg"}`}
        >
          <div className={`container`}>
            <div className="navbar-brand">
              <Link to="/">
                <StaticImage
                  src="../../images/twin-logo/twinLogo3_2024.png"
                  alt="Banner"
                  placeholder="blurred"
                  quality={90}
                  className={styles.logoImage}
                />
              </Link>
            </div>

            {/* navigation for desktop */}
            <div className="collapse navbar-collapse justify-content-end">
              <ul className={`navbar-nav ${styles.navBarList}`}>
                <li className={`nav-item ${styles.navBarListHover}`}>
                  <AnchorLink
                    to="/#about-twin"
                    className="nav-link"
                    onAnchorLinkClick={() =>
                      sendToCT("Subheading_1_clicked", {
                        action: "About Twin CTA",
                      })
                    }
                  >
                    About Twin
                  </AnchorLink>
                </li>
                <li className={`nav-item ${styles.navBarListHover}`}>
                  <AnchorLink
                    to="/#success-stories"
                    className="nav-link"
                    onAnchorLinkClick={() =>
                      sendToCT("Subheading_2_clicked", {
                        action: "Succes stories CTA",
                      })
                    }
                  >
                    Success Stories
                  </AnchorLink>
                </li>
                <li className={`nav-item ${styles.navBarListHover}`}>
                  <Link
                    to="/about-us"
                    className="nav-link"
                    onAnchorLinkClick={() =>
                      sendToCT("Subheading_3_clicked", {
                        action: "Our Team CTA",
                      })
                    }
                  >
                    Our Team
                  </Link>
                </li>
                <li className="nav-item">
                  <AnchorLink
                    to="/#getInTouch"
                    className="navLink"
                    onClick={() =>
                      sendToCT("home_section_CTA2", {
                        action: "User Clicks on Get In Touch",
                      })
                    }
                  >
                    <div className={`${styles.gradientBtn2}`}>
                      Get In Touch
                      {/* <span className="icon-arrow-forward"></span> */}
                    </div>
                  </AnchorLink>
                </li>
                {/* <li className="nav-item">
                  <Link
                    to="/wbdt-care-plan"
                    className="navLink"
                    onClick={() =>
                      sendToCT("home_section_CTA1", {
                        action: "user clicks on get started",
                      })
                    }
                  >
                    <div className={`${styles.gradientBtn}`}>
                      Enroll Now
                    </div>
                  </Link>
                </li> */}
              </ul>
            </div>

            {/* toggler */}
            <div className={`justify-content-end d-flex align-items-center`}>
              {/* for mobile */}
              {props.isHome && (
                <div className="d-none d-lg-none d-xl-none d-xxl-none">
                  <div
                    className="navLink me-3 cursorPointer"
                    onClick={() => {
                      setShowFreeConsultMobileModal(true);
                      sendToCT("Call_icon_clicked", {
                        action: "Call icon on Home page",
                      });
                    }}
                    role="presentation"
                  >
                    <div className={`${styles.callIconMob}`}>
                      <span className="icon-call" aria-hidden="true"></span>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={`cursorPointer ${styles.togglerIcon}`}
                onClick={() => setShowTogglerModal(true)}
                role="presentation"
              >
                <span className="icon-menu" aria-hidden="true"></span>
              </div>
            </div>
          </div>

          {/* inline navigation for mobile */}
          {props.isHome && !isSticky && (
            <div className="d-none d-lg-none d-xl-none d-xxl-none w-100">
              <div className={`container ${styles.twinContainer}`}>
                <ul
                  className={`navbar-nav flex-row justify-content-between ${styles.mobNavBarList}`}
                >
                  <li className="nav-item">
                    <AnchorLink
                      to="/#twin-works"
                      className="nav-link"
                      onAnchorLinkClick={() =>
                        sendToCT("Subheading_1_clicked", {
                          action: "How twin works CTA",
                        })
                      }
                    >
                      How it works
                      {/* <i
                        className={`icon-arrow-down ${styles.mobDownArrow}`}
                      ></i> */}
                    </AnchorLink>
                  </li>
                  <li className="nav-item">
                    <AnchorLink
                      to="/#success-stories"
                      className="nav-link"
                      onAnchorLinkClick={() =>
                        sendToCT("Subheading_2_clicked", {
                          action: "Succes stories CTA",
                        })
                      }
                    >
                      Success Stories
                      {/* <i
                        className={`icon-arrow-down ${styles.mobDownArrow}`}
                      ></i> */}
                    </AnchorLink>
                  </li>
                  <li className="nav-item">
                    <AnchorLink
                      to="/#twin-doctors"
                      className="nav-link"
                      onAnchorLinkClick={() =>
                        sendToCT("Subheading_3_clicked", {
                          action: "Doctors CTA",
                        })
                      }
                    >
                      Doctors
                      {/* <i
                        className={`icon-arrow-down ${styles.mobDownArrow}`}
                      ></i> */}
                    </AnchorLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </nav>
      </div>

      <TogglerModal
        show={showTogglerModal}
        onHide={() => setShowTogglerModal(false)}
      />

      <FreeConsultModal
        show={showFreeConsultModal}
        onHide={() => setShowFreeConsultModal(false)}
      />

      <FreeConsultMobileModal
        show={showFreeConsultMobileModal}
        onHide={() => setShowFreeConsultMobileModal(false)}
      />
    </>
  );
};

export default Header;
